
import { defineComponent } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
// import Toast from "@/common/toast.js";
// import ApiService from "@/core/services/ApiService";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Bus from "@/common/ts/Bus";

export default defineComponent({
  name: "dropdown-3",
  props: {
    row_paper_sub: Object
  },
  methods: {
    loadData() {
      Bus.$emit("loadData");
    },
    loadAllData() {
      Bus.$emit("loadAllData");
    },
    // handleUp(){
    //   const id = this.row_paper_sub.id;
    //   ApiService.post("Paper/PaperSubUp", { id })
    //     .then(({ data }) => {
    //       if (data.success == true) {
    //         this.loadData();
    //         Toast.success(data.message);
    //       } else {
    //         Toast.warning(data.message);
    //       }
    //     })
    //     .catch((error) => {
    //       Toast.error(error.message);
    //     });
    // },
    // handleDown(){
    //   const id = this.row_paper_sub.id;
    //   ApiService.post("Paper/PaperSubDown", { id })
    //     .then(({ data }) => {
    //       if (data.success == true) {
    //         this.loadData();
    //         Toast.success(data.message);
    //       } else {
    //         Toast.warning(data.message);
    //       }
    //     })
    //     .catch((error) => {
    //       Toast.error(error.message);
    //     });
    // },
    // submitDelete(){
    //   const id = this.row_paper_sub.id;
    //   ApiService.post("Paper/PaperSubDelete", { id })
    //     .then(({ data }) => {
    //       if (data.success == true) {
    //         this.loadAllData();
    //         Toast.success(data.message);
    //       } else {
    //         Toast.warning(data.message);
    //       }
    //     })
    //     .catch((error) => {
    //       Toast.error(error.message);
    //     });
    // },
    // handleDelete(){
    //   Swal.fire({
    //     title: "是否删除",
    //     text: "是否删除？一旦提交，无法恢复！",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#DD6B55",
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消"
    //   }).then((isConfirm) => {
    //     if (isConfirm.value) {
    //       this.submitDelete();
    //     }
    //   });
    // },
    handleScore(){
      Bus.$emit("handlePaperSubScore", this.row_paper_sub);
    },
    handleSetQuestionType(question_type){
      Bus.$emit("handleSetQuestionType", question_type)
    },
  },
  mounted(){
    MenuComponent.reinitialization();    
  }
});
