
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import { setCurrentPageButton } from "@/core/helpers/toolbar";
import KTFilterManager from "@/views/exercise/search/FilterManager.vue";
import Bus from "@/common/ts/Bus";

export default defineComponent({
  name: "kt-widget-9",
  components: {
    KTFilterManager
  },
  data() {
    return {
      path: "",
      book_id: "",
      question_type: "",
      //分页
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalRows: -1
      },
      //搜索
      searchKey: "",
      //排序
      sortField: "create_time",
      sortOrder: "descending",
      //数据
      tableData: []
    };
  },
  methods: {
    handleView(item) {
      this.$router.push({
        path: "/exercise/question",
        query: {
          book_id: this.book_id,
          question_id: item.question_id,
          path: this.path
        }
      });
    },
    loadData() {
      const data = {
        prefix: {
          currentPage: this.pagination.currentPage,
          perPage: this.pagination.perPage,
          searchKey: this.searchKey,
          sortField: this.sortField,
          sortOrder: this.sortOrder
        },
        book_id: this.book_id,
        question_type: this.question_type
      };
      ApiService.post("Question/QuestionList", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.tableData = data.data.items;
            this.pagination.totalRows = data.data.meta.totalRows;
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    handleSizeChange(event) {
      this.pagination.perPage = parseInt(event.target.value);
      this.loadData();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.loadData();
    },
    handleSearch() {
      this.loadData();
    },
    initBus() {
      Bus.$on("handleBack", () => {
        this.$router.push({
          path: "/exercise/ready",
          query: { book_id: this.book_id, path: this.path }
        });
      });

      Bus.$on("handleSetQuestionType", (question_type) => {
        this.question_type = question_type;
        this.loadData();
      });
    }
  },
  beforeUnmount() {
    Bus.$off("handleSetQuestionType");
  },
  mounted() {
    setCurrentPageBreadcrumbs("查找试题", ["练习"]);
    setCurrentPageButton("back", true);
    this.initBus();
    this.path = this.$route.query.path as string;
    this.book_id = this.$route.query.book_id as string;
    this.loadData();
  }
});
