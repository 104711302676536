import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "menu-item px-3 mb-3" }
const _hoisted_3 = { class: "menu-item px-3 mb-3" }
const _hoisted_4 = { class: "menu-item px-3 mb-3" }
const _hoisted_5 = { class: "menu-item px-3 mb-3" }
const _hoisted_6 = { class: "menu-item px-3 mb-3" }
const _hoisted_7 = { class: "menu-item px-3 mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: "javascript:void(0)",
        class: "menu-link px-3",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSetQuestionType('')))
      }, " 全部题型 ")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("a", {
        href: "javascript:void(0)",
        class: "menu-link px-3",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSetQuestionType('00')))
      }, " 单选题 ")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("a", {
        href: "javascript:void(0)",
        class: "menu-link px-3",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSetQuestionType('01')))
      }, " 多选题 ")
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("a", {
        href: "javascript:void(0)",
        class: "menu-link px-3",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleSetQuestionType('02')))
      }, " 判断题 ")
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("a", {
        href: "javascript:void(0)",
        class: "menu-link px-3",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleSetQuestionType('03')))
      }, " 填空题 ")
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("a", {
        href: "javascript:void(0)",
        class: "menu-link px-3",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleSetQuestionType('04')))
      }, " 问答题 ")
    ])
  ]))
}